<template lang="pug">
.user-create-edit-dialog
  CreateEditDialog(
    title="Add Member"
    :item="newUser"
    :isOpen="isOpen"
    @close="$emit('close')"
    @submit-form="saveUser"
  )
    template(
      v-slot:form="{ clone, rules }"
    )
      .d-flex.gap-8
        v-text-field(
          outlined
          dense
          :rules="[rules.required]"
          label="Firstname"
          v-model="clone.firstName"
        )

        v-text-field(
          outlined
          dense
          :rules="[rules.required]"
          label="Lastname"
          v-model="clone.lastName"
        )

      v-text-field(
        outlined
        dense
        :rules="[rules.required, rules.email]"
        label="Email"
        v-model="clone.email"
      )

      v-select(
        :loading="isPending"
        outlined
        dense
        label="User Role"
        :items="roles"
        v-model="clone.roleId",
        item-text="name"
        item-value="id"
      )

      v-select(
        v-if="!isCommittee"
        outlined
        dense
        label="Limit To"
        :items="orgTypes"
        v-model="clone.limitById",
        item-text="name"
        item-value="id"
        :rules="[rules.required]"
        multiple
      )

</template>

<script>
import { useFind } from 'feathers-vuex'
import { isNumber, difference, sortBy } from 'lodash'
import { ref } from '@vue/composition-api';
import StateList from '@/components/StateList'
import CreateEditDialog from '@/components/Dialogs/CreateEdit.Dialog'
import formValidations from '../../helpers/formValidations'

export default {
  name: 'UserCreateEditDialog',

  props: {
    title: {
      type: String,
      default: 'Add User'
    },
    isOpen: Boolean,
    editingUser: Object
  },

  components: {
    StateList,
    CreateEditDialog
  },

  setup(props, { emit, root: { $store, $FeathersVuex, $snackError, $snackSuccess } }) {
    const { group } = $store.getters['auth/user']
    const { valid, rules } = formValidations()
    const { User, Role, OrganizationType } = $FeathersVuex.api
    const { items: roles, isPending } = useFind({
      model: Role,
      params: {
        query: { group }
      }
    })
    const isCommittee = group === 'committee'

    let orgTypes = ref([]);
    if (!isCommittee) {
      const { items } = useFind({
        model: OrganizationType,
        params: { query: {} }
      })
      orgTypes = items
    }

    const defaultRoleId = isCommittee ? 4 : 6;
    const userDefaults = {
      group: isCommittee ? 'committee' : 'organization',
      status: 'new',
      stateId: 1,
      roleId: defaultRoleId
    }
    const newUser = new User(props.editingUser || userDefaults)

    const saveUser = async ({ save, clone }) => {
      try {
        clone.limitBy = 'orgType'

        // for whatever reason, `save()` was converting the array
        // and turning into an object. not sure if vuex can handle
        clone.limitById = JSON.stringify(
          sortBy(
            clone.limitById.filter(isNumber),
            val => val // id of the org type
          )
        )

        await save()
        $snackSuccess('Successfully added user')
        emit('close', true)
      } catch (e) {
        $snackError(e)
      }
    }

    return {
      newUser,
      rules,
      valid,

      roles,
      isPending,

      orgTypes,
      isCommittee,

      saveUser
    }
  }
}
</script>

<style>

</style>